import { useRecoilCallback, useRecoilValue } from "recoil";
import { tokenAtom } from "../atoms";
import { userSelector } from "../selectors";
import { createClient } from "../hooks/useClient";

export const useUserContext = () => {
  const user = useRecoilValue(userSelector);
  const clear = useRecoilCallback(({ reset }) => () => {
    reset(tokenAtom);
  });
  const permissions = {
    isAdmin: user?.is_superuser,
    isStaff: user?.is_staff,
    isActive: user?.is_active,
  };
  const logout = clear;

  async function getToken(email, password) {
    return createClient()
      .post("api-token-auth", {
        email,
        password,
      })
      .get("data")
      .get("token");
  }

  const login = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (email, password) => {
        const token = await getToken(email, password);

        snapshot = snapshot.map(({ set }) => {
          set(tokenAtom, token);
          return null;
        });
        gotoSnapshot(snapshot);
      },
  );

  return {
    user,
    login,
    logout,
    permissions,
    getToken,
    clear,
  };
};

export default useUserContext;
