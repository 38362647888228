import React, { useMemo, useState } from "react";
import { Col, Row, Container, Button, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSync,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Search,
  Pagination,
  Table,
  TableContextProvider,
  useListTable,
  useListQuery,
  DateTimeCell,
} from "../../components/Tables";
import HorizontalRule from "../../components/HorizontalRule";
import { useModal } from "../../hooks";

export function AnthologyTasks() {
  const [data, setData] = useState([]);
  const [traceback, setTraceback] = useState(null);
  const { setIsOpen, Modal, ModalBody } = useModal();

  const columns = useMemo(
    () => [
      {
        Header: "Created",
        accessor: "created",
        Cell: DateTimeCell,
      },
      {
        disableSortBy: true,
        Header: "Type",
        accessor: "type_label",
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          const { original } = row;
          const { is_success, is_error, status_label } = original;

          if (is_success) {
            return (
              <>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success"
                />{" "}
                {status_label}
              </>
            );
          } else if (is_error) {
            return (
              <>
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />{" "}
                {status_label}
              </>
            );
          }
          return value;
        },
      },
      {
        disableSortBy: true,
        Header: "Exception",
        accessor: "exception",
      },
      {
        id: "options",
        Cell: ({ row }) => {
          const { original } = row;
          const { traceback } = original;

          if (!traceback) return null;

          return (
            <Button
              color="link"
              onClick={() => {
                setTraceback(traceback);
                setIsOpen(true);
              }}
            >
              View error
            </Button>
          );
        },
      },
    ],
    [setIsOpen, setTraceback],
  );

  const table = useListTable({
    columns,
    data,
    initialState: {
      pageSize: 100,
    },
  });

  const { isFetching } = useListQuery({
    table,
    setData,
    url: "anthology_tasks",
  });

  return (
    <div className="flex-1 overflow-x-hidden">
      <Modal size="lg">
        <ModalHeader>Error details</ModalHeader>
        <ModalBody>
          <code className="text-sm">{traceback}</code>
        </ModalBody>
      </Modal>
      <Container className="container-a mx-auto">
        <h2 className="heading-b mb-4">
          <FontAwesomeIcon icon={faSync} className="mr-4" />
          Anthology Tasks
        </h2>
      </Container>
      <HorizontalRule className="-mt-4" />
      <Container className="container-a mx-auto">
        <TableContextProvider table={table}>
          <Row form>
            <Col>
              <Search placeholder={"Search..."} isFetching={isFetching} />
            </Col>
          </Row>
          <Table size="sm" hover striped />
          <Pagination />
        </TableContextProvider>
      </Container>
    </div>
  );
}
