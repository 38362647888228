import React, { useCallback, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../components/react-hook-form";
import { Page } from "../../components/common";
import useClient from "../../hooks/useClient";
import { Select } from "../../components/common";

export const Payouts = () => {
  const url = "settings";
  const client = useClient();
  const methods = useForm({
    defaultValues: { account: null },
    mode: "all",
  });
  const { handleSubmit, reset } = methods;
  const [accounts, setAccounts] = useState([]);
  const accountsWithCodes = useMemo(
    () => accounts.filter((e) => !!e.Code),
    [accounts],
  );
  const onSubmit = async (x) => {
    try {
      const data = await client.patch(url, x).get("data");
      reset(data);
      toast.success("Changes saved.");
    } catch (e) {
      toast.error("Something went wrong when saving the Xero settings.");
    }
  };

  const load = async () => {
    try {
      const [settings, accounts] = await Promise.all([
        client.get(url).get("data"),
        client.get("xero_account?Type=BANK").get("data"),
      ]);
      setAccounts(accounts);
      reset(settings);
    } catch (e) {
      toast.error(
        "Something went wrong when trying to load the Xero subsettings",
      );
    }
  };

  const onMount = useCallback(load, [client, reset, url]);
  useEffect(() => void onMount(), [onMount]);

  return (
    <Page>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            className="form-group"
            label="Payout Xero Account"
            name="payout_account"
          >
            <Select
              isClearable
              valueKey="id"
              options={accounts}
              placeholder="Xero Account"
              name="payout_account"
              rules={{ required: false }}
            />
          </FormGroup>
          <FormGroup
            className="form-group"
            label="Anthology Default Payment Xero Account"
            name="payment_account"
          >
            <Select
              isClearable
              valueKey="id"
              options={accountsWithCodes}
              placeholder="Xero Account"
              name="payment_account"
              rules={{ required: false }}
            />
          </FormGroup>
          <FormGroup
            className="form-group"
            label="Refund Xero Account"
            name="refund_account"
          >
            <Select
              isClearable
              valueKey="id"
              options={accountsWithCodes}
              placeholder="Xero Account"
              name="refund_account"
              rules={{ required: false }}
            />
          </FormGroup>
          <div className="w-100 flex justify-end">
            <SubmitButton>Save</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};

export default Payouts;
