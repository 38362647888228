import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import { Alert } from "reactstrap";
import { useClient } from "../../hooks";
import { useForm, FormProvider } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../components/react-hook-form";
import { Row, Col } from "reactstrap";
import { getPasswordError, validateEmail } from "../../services/validators";

export const Signup = () => {
  const methods = useForm({
    defaultValues: {},
    mode: "all",
  });
  const { handleSubmit, watch, formState, register } = methods;
  const [error, setError] = useState(null);
  const [finished, setFinished] = useState(false);
  const { errors, dirtyFields, submitCount } = formState;
  const password = watch("password");
  const password_confirm = watch("password_confirm");
  const client = useClient();
  const navigate = useNavigate();
  const onSubmit = async (formData) => {
    try {
      const payload = {
        email: formData.email,
        password: formData.password,
      };
      await client.post("/register", payload).get("data");
      toast.success(
        "Registration form sent successfully. Please check your email inbox for confirmation email.",
      );
      setFinished(true);
      navigate("/dashboard");
    } catch (err) {
      const err_message =
        "Something went wrong and the registration form has not been submitted";
      const code = err.response?.data?.code;
      const detail = err.response?.data?.detail;
      setError(getPasswordError(err));
      if (code === "user_exists" || code === "invalid_password")
        toast.error(detail ? detail : err_message);
      else toast.error(err_message);
    }
  };

  return (
    <>
      <div className="max-w-xs mx-auto">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <h2 className="text-xl font-bold mb-2">
                <Trans>Create Account</Trans>
              </h2>
            </div>
            {!finished ? (
              <>
                <FormGroup label={"Email address"} name="email">
                  <input
                    {...register("email", {
                      required: true,
                      validate: validateEmail(),
                    })}
                    name="email"
                    type="email"
                    className="form-control pr-3"
                    placeholder="Enter email"
                  />
                  {errors.email && (
                    <div className="alert alert-danger mt-1 mb-0 p-2">
                      Please input a valid email address.
                    </div>
                  )}
                </FormGroup>
                <FormGroup label={"Password"} name="password">
                  <input
                    {...register("password", {
                      required: true,
                      minLength: 10,
                      maxLength: 255,
                    })}
                    name="password"
                    type="password"
                    className="form-control pr-3"
                    placeholder="Enter password"
                  />
                  {errors.password && errors.password.message && (
                    <div className="alert alert-danger mt-1 mb-0 p-2">
                      {errors.password?.message}
                    </div>
                  )}
                </FormGroup>

                <FormGroup label={"Confirm Password"} name="password_confirm">
                  <input
                    {...register("password_confirm", {
                      required: true,
                      minLength: 10,
                      maxLength: 255,
                      validate: (value) => value === password,
                    })}
                    name="password_confirm"
                    type="password"
                    className="form-control pr-3"
                    placeholder="Enter password"
                  />
                </FormGroup>
                {errors.password_confirm &&
                password_confirm !== password &&
                ((dirtyFields.password && dirtyFields.password_confirm) ||
                  submitCount) ? (
                  <Alert color="danger">Passwords do not match</Alert>
                ) : null}
                <div className="text-sm text-hc-primary my-2">
                  Ensure your password contains at least 10 characters,
                  including at least one uppercase letter, lowercase letter and
                  number.
                </div>
                <div>{error}</div>
                <div className="mb-3">
                  <SubmitButton className="w-full" block>
                    Register
                  </SubmitButton>
                </div>

                <Row className="items-center">
                  <Col>
                    <Link
                      to="../login"
                      className="underline text-sm text-lighter"
                    >
                      Already have an account?
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      to="../forgotten_password"
                      className="underline text-sm text-lighter"
                    >
                      Forgot your password?
                    </Link>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="space-y-4">
                <p>You have successfully completed your registration.</p>
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Signup;
