import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { usePageCountFromTotal, useServerFilterParameters } from "../plugins";

export const useListTable = ({ columns, data, initialState }, ...options) => {
  return useTable(
    {
      columns,
      data,
      initialState,
      manualPagination: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      manualFilters: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePageCountFromTotal,
    usePagination,
    useServerFilterParameters,
    ...options,
  );
};
