import React from "react";
import classNames from "classnames/dedupe";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

export const FormGroup = ({
  label,
  name,
  children,
  className,
  helpText,
  required,
  showError = true,
  labelClassName,
  ...props
}) => {
  const { formState } = useFormContext();
  const errors = formState.errors;
  const { submitCount, touchedFields } = formState;
  const error =
    get(touchedFields, name) || submitCount ? get(errors, name, null) : null;

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-error": error,
        },
        className,
      )}
      {...props}
    >
      {label ? (
        <label className={classNames("form-group__label", labelClassName)}>
          {label} {required ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      {children}
      {helpText ? (
        <div className="mt-1 opacity-75 text-sm">{helpText}</div>
      ) : null}
      {showError && error?.message ? (
        <div className="mt-1 text-sm text-red-500">{error?.message}</div>
      ) : null}
    </div>
  );
};
