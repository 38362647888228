import React, { useEffect } from "react";
import { useClient } from "../../hooks";
import { Page } from "../../components/common";
import { FormGroup, Table } from "reactstrap";
import { chain, keyBy, orderBy } from "lodash";
import Select from "react-select";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { SubmitButton } from "../../components/react-hook-form";
import { toast } from "react-toastify";
import { useQueries } from "@tanstack/react-query";

const url = "anthology_billing_code_xero_links";
const xeroAccountsUrl = `${url}/xero_accounts`;
const billingCodesUrl = `${url}/anthology_billing_codes`;

function selectXeroAccounts(xeroAccounts) {
  return chain(xeroAccounts)
    .filter((i) => i.Type !== "BANK")
    .map((i) => ({
      ...i,
      FullName: [i.Code, i.Name].filter((i) => i).join(" - "),
    }))
    .orderBy("FullName")
    .value();
}

function selectBillingCodes(billingCodes) {
  return orderBy(billingCodes, "Code");
}

export function AnthologyBillingCodeXeroLinks() {
  const client = useClient();
  const form = useForm();
  const { setValue, handleSubmit, watch } = form;
  const mapping = watch("mapping");

  const queries = useQueries({
    queries: [
      { queryKey: [xeroAccountsUrl], select: selectXeroAccounts },
      { queryKey: [billingCodesUrl], select: selectBillingCodes },
      { queryKey: [url] },
    ],
  });
  const [{ data: xeroAccounts }, { data: billingCodes }, { data }] = queries;

  useEffect(() => {
    if (!(xeroAccounts && billingCodes && data)) return;

    const dataByBillingCode = keyBy(data, "anthology_billing_code_id");
    const xeroAccountsById = keyBy(xeroAccounts, "AccountID");

    const mapping = {};

    for (const { Id } of billingCodes) {
      const xeroAccountId = dataByBillingCode[Id]?.xero_account_id || null;
      mapping[Id] = xeroAccountsById[xeroAccountId] || null;
    }

    setValue("mapping", mapping);
  }, [xeroAccounts, billingCodes, data, setValue]);

  async function onSubmit({ mapping }) {
    const payload = chain(mapping)
      .map((v, k) => ({
        anthology_billing_code_id: k,
        xero_account_id: v?.AccountID,
      }))
      .filter((i) => i.xero_account_id)
      .value();
    await client.post(url, payload);
    toast.success("Saved successfully");
  }

  if (!mapping) return null;

  return (
    <Page>
      <FormProvider {...form}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Billing Code</th>
              <th>Xero Account</th>
            </tr>
          </thead>
          <tbody>
            {billingCodes.map((i) => (
              <tr key={i.Id}>
                <td>
                  {i.Code} {i.Name}
                </td>
                <td>
                  <FormGroup className="mb-0" style={{ width: "20rem " }}>
                    <Controller
                      name={`mapping.${i.Id}`}
                      render={(props) => (
                        <Select
                          options={xeroAccounts}
                          getOptionLabel={(i) => i.FullName}
                          getOptionValue={(i) => i.AccountID}
                          {...props.field}
                        />
                      )}
                    />
                  </FormGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="flex justify-end">
          <SubmitButton onClick={handleSubmit(onSubmit)}>Save</SubmitButton>
        </div>
      </FormProvider>
    </Page>
  );
}
