import React from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useTableContext } from "../hooks";
import { DebouncedInput } from "../../common";

export const Search = ({
  className,
  table,
  icon = faSearch,
  addonType = "prepend",
  placeholder,
  isFetching,
  ...props
}) => {
  const _table = useTableContext();
  table = table ?? _table;
  const { setGlobalFilter, state } = table;
  const { globalFilter } = state;

  return (
    <div className={classNames("form-group", className)} {...props}>
      <InputGroup>
        {icon ? (
          <InputGroupText>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroupText>
        ) : null}
        <DebouncedInput
          onChange={(e) => setGlobalFilter(e.target.value)}
          value={globalFilter}
          className="form-control"
          placeholder={placeholder}
        />
        {isFetching ? (
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
            className={classNames(
              "absolute inset-y-0 h-full opacity-50",
              "right-0 mr-3",
            )}
            size="lg"
          />
        ) : null}
      </InputGroup>
    </div>
  );
};
