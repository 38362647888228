import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useClient } from "../../hooks";
import { useForm, FormProvider } from "react-hook-form";
import {
  FormGroup,
  SubmitButton,
  Input,
} from "../../components/react-hook-form";
import { Row, Col } from "reactstrap";
import { validateEmail } from "../../services/validators";

export const ForgottenPassword = () => {
  const methods = useForm({
    defaultValues: {},
    mode: "all",
  });
  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const client = useClient({
    sendAuth: false,
  });
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const onSubmit = (formData) => {
    client
      .post("/password_reset_request", {
        username: formData.email,
      })
      .then((responseData) => {
        setSuccess(true);
        toast.success("Reset request sent!");
        navigate("/dashboard");
      })
      .catch((ex) => {
        console.log(ex);
        // const errorMessages = ex?.response?.data?.detail
        // for (const [key, value] of Object.entries(errorMessages)) {
        //   setError(key, { message: value[0] });
        // }
        toast.error(
          "An error occurred and the password reset request was not sent.",
        );
      });
  };

  const subTitle = success ? (
    <div>
      If an account exists for {getValues("email")}, you will get an email with
      instructions on resetting your password. If it doesn't arrive, be sure to
      check your spam folder.
    </div>
  ) : (
    <div>
      Please enter the email address you registered with and we'll send you a
      link to reset your password.
    </div>
  );

  const backToLogin = (
    <Link to="../login" className="underline text-sm text-lighter">
      Back to login
    </Link>
  );

  return (
    <>
      <div className="max-w-xs mx-auto text-primary">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="text-xl font-bold mb-2">Forgot your password?</h2>
            <div className="text-sm text-hc-primary mb-4">{subTitle}</div>

            <FormGroup label="Email address" name="email">
              <Input
                type="email"
                className="form-control pr-3"
                name="email"
                placeholder="Enter email"
                rules={{
                  required: true,
                  validate: validateEmail(),
                }}
              />
              {errors.email && (
                <div className="alert alert-danger mt-1 mb-0 p-2">
                  Please input a valid email address.
                </div>
              )}
              {/* {error} */}
            </FormGroup>
            <Row className="items-center justify-between">
              <Col>{backToLogin}</Col>
              <Col>
                <SubmitButton className="w-full" block>
                  Submit
                </SubmitButton>
              </Col>
            </Row>
            <div className="mb-3"></div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default ForgottenPassword;
